@charset "UTF-8";
/*
 * Retina Sprites for Compass
 * by:              Gaya Kessler
 * last update:     03/11/14
 *
 * Usage:
 * 1. create two folders in your image directory (in this case 'icons' and 'icons-2x').
 * 2. adjust the foldernames defined below if you use different names.
 * 3. create sprite images for pixel ratio 1 screens and put them in the first folder.
 * 4. create sprite images for pixel ratio 2 screens and put them in the second folder, use the same filenames.
 * 5. use the sprite-image in your Sass/Scss using: '@include use-sprite(<sprite-name>)'
 */
/**
 * フォントの指定
 */
html {
  font-size: 62.5%;
}

body > * {
  font-size: 13px;
  font-size: 1.3rem;
  line-height: 1.8;
}

@media screen and (min-width: 768px) {
  body {
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 1.5;
  }
}
@media screen and (min-width: 1024px) {
  body {
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 1.5;
  }
}
#first {
  position: relative;
  width: 100%;
  height: 320px;
  height: 32rem;
}
#first .hidden {
  opacity: 0;
  display: block;
  visibility: hidden;
}
#first .fade {
  -moz-transition-duration: 1.2s;
  -o-transition-duration: 1.2s;
  -webkit-transition-duration: 1.2s;
  transition-duration: 1.2s;
}

#first > div, #first > div > img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

#first > div > img {
  -ms-zoom: 1;
  /* IE */
  -moz-transform: scale(1);
  /* Firefox */
  -moz-transform-origin: 0 0;
  -o-transform: scale(1);
  /* Opera */
  -o-transform-origin: 0 0;
  -webkit-transform: scale(1);
  /* Safari And Chrome */
  -webkit-transform-origin: 0 0;
  -ms-transform: scale(1);
  /* Firefox */
  -ms-transform-origin: 0 0;
  transform: scale(1);
  /* Standard Property */
  transform-origin: 0 0;
  /* Standard Property */
}

.first__movie__play {
  position: absolute;
  display: block;
  top: 145px;
  top: 14.5rem;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 100;
  text-indent: -119988px;
  overflow: hidden;
  text-align: left;
  text-transform: capitalize;
  background-image: url('../img/play.png?1430186767');
  background-repeat: no-repeat;
  background-position: 50% 50%;
  width: 30.5px;
  width: 3.05rem;
  height: 30.5px;
  height: 3.05rem;
  -moz-background-size: 100% 100%;
  -o-background-size: 100% 100%;
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}

@media screen and (min-width: 768px) {
  #first {
    height: 768px;
    height: 76.8rem;
  }

  .first__movie__play {
    top: 352px;
    top: 35.2rem;
    text-indent: -119988px;
    overflow: hidden;
    text-align: left;
    text-transform: capitalize;
    background-image: url('../img/pc/play.png?1430190057');
    background-repeat: no-repeat;
    background-position: 50% 50%;
    width: 60.5px;
    width: 6.05rem;
    height: 60.5px;
    height: 6.05rem;
    -moz-background-size: 100% 100%;
    -o-background-size: 100% 100%;
    -webkit-background-size: 100% 100%;
    background-size: 100% 100%;
  }
}
/*
 * Retina Sprites for Compass
 * by:              Gaya Kessler
 * last update:     03/11/14
 *
 * Usage:
 * 1. create two folders in your image directory (in this case 'icons' and 'icons-2x').
 * 2. adjust the foldernames defined below if you use different names.
 * 3. create sprite images for pixel ratio 1 screens and put them in the first folder.
 * 4. create sprite images for pixel ratio 2 screens and put them in the second folder, use the same filenames.
 * 5. use the sprite-image in your Sass/Scss using: '@include use-sprite(<sprite-name>)'
 */
.section-nav__up > span {
  background-image: url('../img/arrows-se1adabdc2a.png');
  background-repeat: no-repeat;
  overflow: hidden;
  display: block;
  background-size: 35px 36px;
  background-size: 3.5rem 3.6rem;
  background-position: 0 -19px;
  background-position: 0 -1.9rem;
  height: 17px;
  height: 1.7rem;
  line-height: 17px;
  line-height: 1.7rem;
  width: 35px;
  width: 3.5rem;
}

.section-nav__down > span {
  background-image: url('../img/arrows-se1adabdc2a.png');
  background-repeat: no-repeat;
  overflow: hidden;
  display: block;
  background-size: 35px 36px;
  background-size: 3.5rem 3.6rem;
  background-position: 0 0;
  background-position: 0 0;
  height: 16px;
  height: 1.6rem;
  line-height: 16px;
  line-height: 1.6rem;
  width: 35px;
  width: 3.5rem;
}

#section-nav {
  position: fixed;
  bottom: 45px;
  bottom: 4.5rem;
  height: 16px;
  height: 1.6rem;
  width: 16px;
  width: 1.6rem;
  left: 0;
  right: 0;
  z-index: 800;
}

@-moz-keyframes section-nav {
  0%, 100% {
    top: 0;
  }
  50% {
    top: 5px;
    top: .5rem;
  }
}
@-webkit-keyframes section-nav {
  0%, 100% {
    top: 0;
  }
  50% {
    top: 5px;
    top: .5rem;
  }
}
@keyframes section-nav {
  0%, 100% {
    top: 0;
  }
  50% {
    top: 5px;
    top: .5rem;
  }
}
#section-nav > a {
  position: absolute;
  left: 142.5px;
  left: 14.25rem;
  -moz-animation: section-nav 2s ease-in-out -1s infinite alternate;
  -webkit-animation: section-nav 2s ease-in-out -1s infinite alternate;
  animation: section-nav 2s ease-in-out -1s infinite alternate;
}
#section-nav > a > span {
  text-indent: 200%;
  white-space: nowrap;
  overflow: hidden;
}

.section-nav__up > span {
  background-image: url('../img/pc/arrows-s3378d9b9a8.png');
  background-repeat: no-repeat;
  overflow: hidden;
  display: block;
  background-size: 36px 54px;
  background-size: 3.6rem 5.4rem;
  background-position: 0 -38px;
  background-position: 0 -3.8rem;
  height: 16px;
  height: 1.6rem;
  line-height: 16px;
  line-height: 1.6rem;
  width: 36px;
  width: 3.6rem;
}

.section-nav__down > span {
  background-image: url('../img/pc/arrows-s3378d9b9a8.png');
  background-repeat: no-repeat;
  overflow: hidden;
  display: block;
  background-size: 36px 54px;
  background-size: 3.6rem 5.4rem;
  background-position: 0 -9px;
  background-position: 0 -0.9rem;
  height: 16px;
  height: 1.6rem;
  line-height: 16px;
  line-height: 1.6rem;
  width: 36px;
  width: 3.6rem;
}

@media screen and (min-width: 480px) {
  #section-nav {
    bottom: 20px;
    bottom: 2rem;
  }
}
@media screen and (min-width: 768px) {
  #section-nav {
    bottom: 20px;
    bottom: 2rem;
    height: 16px;
    height: 1.6rem;
    width: 36px;
    width: 3.6rem;
  }

  #section-nav > a {
    left: 494px;
    left: 49.4rem;
  }
}
#concept {
  padding-bottom: 15px;
  padding-bottom: 1.5rem;
  font-size: 15px;
  font-size: 1.5rem;
  line-height: 2;
}
#concept h1 {
  text-indent: -119988px;
  overflow: hidden;
  text-align: left;
  text-transform: capitalize;
  background-image: url('../img/concept__title.png?1430186767');
  background-repeat: no-repeat;
  background-position: 50% 50%;
  width: 256.5px;
  width: 25.65rem;
  height: 20px;
  height: 2rem;
  -moz-background-size: 100% 100%;
  -o-background-size: 100% 100%;
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
  margin: 24px auto 24px;
  margin: 2.4rem auto 2.4rem;
}
#concept > p {
  padding: 0 32px;
  padding: 0 3.2rem;
}
#concept .concept__more {
  display: block;
  color: #ffa000;
  text-align: center;
}

/*#concept__images {
  margin-top: 15px; margin-top: rem(15px);
  @include pie-clearfix;
  .concept__image {
    opacity: 1;
    @include transition-property(all);
    @include transition-duration(1.8s);
    @include transition-timing-function(ease);
  }
  .concept__image.hidden {
    display: block;
    visibility: hidden;
    opacity: 0;
    zoom: 0;
  }
}*/
/*#concept__images__1,
#concept__images__3,
#concept__images__5,
#concept__images__7 {
  float: left;
}
#concept__images__2,
#concept__images__4,
#concept__images__6,
#concept__images__8 {
  float: right;
}*/
@media screen and (min-width: 768px) {
  #concept {
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 2;
  }

  #concept h1 {
    text-indent: -119988px;
    overflow: hidden;
    text-align: left;
    text-transform: capitalize;
    background-image: url('../img/pc/concept__title.png?1430190057');
    background-repeat: no-repeat;
    background-position: 50% 50%;
    width: 512.5px;
    width: 51.25rem;
    height: 38.5px;
    height: 3.85rem;
    -moz-background-size: 100% 100%;
    -o-background-size: 100% 100%;
    -webkit-background-size: 100% 100%;
    background-size: 100% 100%;
    margin: 60px auto 60px;
    margin: 6rem auto 6rem;
  }

  #concept > p {
    margin: 0 auto;
    padding: 0;
    width: 512.5px;
    width: 51.25rem;
  }

  /*  #concept__images {
      margin-top: 10px; margin-top: rem(10px);
    }
    .concept__image {
      margin-top:30px; margin-top:rem(30px); 
    }*/
  /*  #concept__images__2,
    #concept__images__4,
    #concept__images__6,
    #concept__images__8 {
      float: left;
    }*/
}
#products {
  padding-top: 20px;
  padding-top: 2rem;
}

.product {
  position: relative;
  padding-bottom: 48px;
  padding-bottom: 4.8rem;
  *zoom: 1;
}
.product:after {
  content: "";
  display: table;
  clear: both;
}
.product .hidden {
  display: block;
  visibility: hidden;
  opacity: 0;
}

.product__images {
  position: relative;
  *zoom: 1;
}
.product__images:after {
  content: "";
  display: table;
  clear: both;
}
.product__images a {
  display: block;
}
.product__images img {
  position: relative;
  top: 0;
  width: 320px;
  width: 32rem;
}

.product__content {
  position: relative;
  *zoom: 1;
}
.product__content:after {
  content: "";
  display: table;
  clear: both;
}

.product__title, .product__description {
  top: 0;
  cursor: pointer;
}

.product__title {
  width: 320px;
  width: 32rem;
  text-align: center;
}
.product__title a {
  display: block;
}
.product__title a > img {
  margin: 0 auto;
  width: 50%;
}

.product__description {
  padding: 0 32px;
  padding: 0 3.2rem;
}

@media screen and (min-width: 768px) {
  #products {
    *zoom: 1;
    padding-top: 40px;
    padding-top: 4rem;
  }
  #products:after {
    content: "";
    display: table;
    clear: both;
  }

  .products__category {
    *zoom: 1;
  }
  .products__category:after {
    content: "";
    display: table;
    clear: both;
  }

  .product .product__content {
    width: 420px;
    width: 42rem;
    margin: 0 auto;
  }
  .product .product__title {
    width: 420px;
    width: 42rem;
  }
  .product .product__description {
    padding: 0;
    margin-top: -10px;
    margin-top: -1rem;
    width: auto;
  }

  .product_layout--default {
    width: 512px;
    width: 51.2rem;
    float: left;
  }
  .product_layout--default .product__images img {
    width: 512px;
    width: 51.2rem;
  }
  .product_layout--default .product__content--left {
    margin: 0 30px 0 auto;
    margin: 0 3rem 0 auto;
  }
  .product_layout--default .product__content--right {
    margin: 0 auto 0 30px;
    margin: 0 auto 0 3rem;
  }

  .product_layout--wide {
    width: 1024px;
    width: 102.4rem;
    clear: both;
  }
  .product_layout--wide .product__images img {
    width: 1024px;
    width: 102.4rem;
  }
  .product_layout--wide .product__title {
    width: 640px;
    width: 64rem;
    margin-left: -12rem;
  }

  .product_layout--image-left {
    width: 1024px;
    width: 102.4rem;
    clear: both;
    *zoom: 1;
  }
  .product_layout--image-left:after {
    content: "";
    display: table;
    clear: both;
  }
  .product_layout--image-left .product__images {
    width: 512px;
    width: 51.2rem;
    float: left;
  }
  .product_layout--image-left .product__images img {
    width: 512px;
    width: 51.2rem;
  }
  .product_layout--image-left .product__content {
    float: right;
    width: 420px;
    width: 42rem;
    margin-right: 64px;
    margin-right: 6.4rem;
    margin-top: 260px;
    margin-top: 26rem;
  }
  .product_layout--image-left .product__description {
    width: 420px;
    width: 42rem;
    margin: -10px auto 0;
    margin: -1rem auto 0;
  }

  .product_layout--image-right {
    width: 1024px;
    width: 102.4rem;
    clear: both;
    *zoom: 1;
  }
  .product_layout--image-right:after {
    content: "";
    display: table;
    clear: both;
  }
  .product_layout--image-right .product__images {
    width: 512px;
    width: 51.2rem;
    float: right;
  }
  .product_layout--image-right .product__images img {
    width: 512px;
    width: 51.2rem;
  }
  .product_layout--image-right .product__content {
    float: left;
    width: 420px;
    width: 42rem;
    margin-left: 64px;
    margin-left: 6.4rem;
    margin-top: 260px;
    margin-top: 26rem;
  }
  .product_layout--image-right .product__description {
    width: 420px;
    width: 42rem;
    margin: -10px auto 0;
    margin: -1rem auto 0;
  }

  #products__magdot img, #products__magfit img {
    top: 100px;
    top: 10rem;
  }

  #products__magmat img {
    top: 70px;
    top: 7rem;
  }

  #cat-mobile {
    margin-bottom: 80px;
    margin-bottom: 8rem;
  }

  #products__neo .products__neo__link {
    margin-right: 2rem;
    margin-top: 25px;
    margin-top: 2.5rem;
  }
}
#silhouette {
  padding: 112px;
  padding: 11.2rem;
  background-color: rgba(255, 255, 255, 0.5);
}
#silhouette h1 {
  text-indent: -119988px;
  overflow: hidden;
  text-align: left;
  text-transform: capitalize;
  background-image: url('../img/pc/silhouette.png?1420594675');
  background-repeat: no-repeat;
  background-position: 50% 50%;
  width: 800px;
  width: 80rem;
  height: 190px;
  height: 19rem;
  -moz-background-size: 100% 100%;
  -o-background-size: 100% 100%;
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}

#Home {
  padding-right: 0px !important;
}
#Home .fs-lightbox-overlay {
  z-index: 3100;
}
#Home .fs-lightbox {
  z-index: 3101;
}
#Home .fs-lightbox-container {
  z-index: 3103;
}
#Home .fs-lightbox-mobile.fs-lightbox-touch .fs-lightbox-meta {
  z-index: 3104;
}
#Home .fs-lightbox-close {
  z-index: 3105;
}
#Home .fs-lightbox-loading_icon {
  z-index: 3105;
}
#Home .fs-lightbox-control {
  z-index: 3105;
}
#Home .fs-lightbox-mobile.fs-lightbox-touch.fs-lightbox-open {
  overflow-y: auto;
}
#Home .fs-lightbox-mobile.fs-lightbox-touch .fs-lightbox-controls {
  z-index: 3105;
  display: none;
}
#Home .fs-lightbox-mobile .fs-lightbox-content {
  padding: 0;
}
